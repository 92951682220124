import {
  Button,
  Divider,
  Image,
  Modal,
  Descriptions,
  message,
  List,
  Avatar,
} from "antd"
import {
  EyeOutlined,
  CheckCircleFilled,
  CheckCircleOutlined,
  MinusCircleOutlined,
} from "@ant-design/icons"
import * as API from "common/api"
import * as COMMONS from "common/common"
import { TapAnimationComponent } from "components"
import { motion } from "framer-motion"
import moment from "moment"
import { useQuery } from "@tanstack/react-query"
import { useState } from "react"
import { useNavigate } from "react-router-dom"

const DetailModal = (props) => {
  const {
    publicSettings,
    isMemberDetailModalVisible,
    hideMemberDetailModal,
    currentMember,
  } = props

  const navigate = useNavigate()
  const isMountedRef = COMMONS.USE_IS_MOUNTED_REF()

  const [campaignQuestions, setCampaignQuestions] = useState([])

  useQuery(
    [API.QUERY_KEY_ADMIN_CAMPAIGN_QUESTIONS],
    () => API.ADMIN_GET_CAMPAIGN_QUESTIONS(),
    {
      onSuccess: (response) => {
        if (isMountedRef.current) {
          setCampaignQuestions(response?.data || [])
        }
      },
      onError: (error) => {
        if (error?.response?.status === COMMONS.RESPONSE_PERMISSION_ERROR) {
          navigate(COMMONS.PERMISSION_ERROR_ROUTE)
        } else if (error?.response?.status === COMMONS.RESPONSE_SESSION_ERROR) {
          message.warning({
            content: COMMONS.ERROR_SESSION_MSG,
            key: COMMONS.MESSAGE_SESSION_ERROR_KEY,
          })
          navigate(COMMONS.ADMIN_LOGIN_ROUTE)
        } else if (error?.response?.status === COMMONS.RESPONSE_SYSTEM_ERROR) {
          message.error({
            content: COMMONS.ERROR_SYSTEM_MSG,
            key: COMMONS.MESSAGE_SYSTEM_ERROR_KEY,
          })
        } else {
          message.error({
            content: COMMONS.ERROR_SYSTEM_MSG,
            key: COMMONS.MESSAGE_SYSTEM_ERROR_KEY,
          })
        }
      },
    }
  )

  return (
    <>
      <Modal
        title="お客様詳細"
        open={isMemberDetailModalVisible}
        onCancel={hideMemberDetailModal}
        bodyStyle={{
          maxHeight: "90vh",
          overflowY: "auto",
          overflowX: "hidden",
        }}
        footer={null}
        destroyOnClose
        centered
      >
        <motion.div
          className="flex flex-col justify-center"
          variants={COMMONS.ANIMATION_VARIANT_STAGGER_CONTAINER}
          initial="hidden"
          animate="show"
          exit="hidden"
        >
          <motion.div variants={COMMONS.ANIMATION_VARIANT_STAGGER_ITEM}>
            <div className="flex justify-center">
              {currentMember?.picUrl ? (
                <Image
                  preview={{
                    mask: <EyeOutlined />,
                    src: currentMember.picUrl,
                    maskClassName: "rounded-full",
                  }}
                  width={100}
                  height={100}
                  style={{
                    border: `4px solid ${COMMONS.WHITE_COLOR}`,
                  }}
                  className="rounded-full"
                  src={`${currentMember.picUrl}/large`}
                  fallback="/no-image.png"
                />
              ) : (
                <Image
                  preview={false}
                  width={100}
                  height={100}
                  style={{
                    border: `4px solid ${COMMONS.WHITE_COLOR}`,
                  }}
                  className="rounded-full"
                  src="/no-image.png"
                />
              )}
            </div>
            <div className="mb-8">
              <p className="text-center font-bold text-xl">
                {currentMember?.lastName || "ー"}{" "}
                {currentMember?.firstName || "ー"}様
              </p>
              <p className="text-center text-sm">
                （{currentMember?.lastNameKana || "ー"}{" "}
                {currentMember?.firstNameKana || "ー"}）
              </p>
            </div>
          </motion.div>
          <motion.div variants={COMMONS.ANIMATION_VARIANT_STAGGER_ITEM}>
            <Descriptions column={1} layout="horizontal" bordered>
              <Descriptions.Item label="LINE名" className="text-center">
                {currentMember?.displayName || "ー"}
              </Descriptions.Item>
              <Descriptions.Item label="電話番号" className="text-center">
                {currentMember?.telephone || "ー"}
              </Descriptions.Item>
              <Descriptions.Item label="メールアドレス" className="text-center">
                {currentMember?.email || "ー"}
              </Descriptions.Item>
              <Descriptions.Item label="住所" className="text-center">
                〒
                {`${
                  currentMember?.postalCode
                    ? COMMONS.POSTAL_CODE_INSERT_CHARACTER(
                        currentMember.postalCode,
                        3,
                        "-"
                      )
                    : "ー"
                } ${currentMember?.address || ""}${
                  currentMember?.building || ""
                }`}
              </Descriptions.Item>
              <Descriptions.Item label="キャンペーン" className="text-center">
                {currentMember?.isCampaign ? (
                  currentMember?.candidateAt ? (
                    <p>
                      <CheckCircleFilled className="text-custom-green mr-1" />
                      <span>当選済み</span>
                    </p>
                  ) : (
                    <p>
                      <CheckCircleOutlined className="text-custom-green mr-1" />
                      <span>当選待ち</span>
                    </p>
                  )
                ) : (
                  <p>
                    <MinusCircleOutlined className="text-yellow-600" />
                  </p>
                )}
              </Descriptions.Item>
              <Descriptions.Item label="当選⽇" className="text-center">
                {currentMember?.candidateAt
                  ? moment(currentMember.candidateAt).format("YYYY/M/D")
                  : "ー"}
              </Descriptions.Item>
              <Descriptions.Item label="入会日" className="text-center">
                {currentMember?.memberSince
                  ? moment(currentMember.memberSince).format("YYYY/M/D")
                  : "ー"}
              </Descriptions.Item>
            </Descriptions>
          </motion.div>
          {currentMember?.campaignAnswers &&
          currentMember?.campaignAnswers?.length > 0 ? (
            <>
              <Divider>キャンペーン応募詳細</Divider>
              <List
                dataSource={campaignQuestions}
                itemLayout="horizontal"
                size="small"
                renderItem={(question, i) => (
                  <List.Item>
                    <List.Item.Meta
                      avatar={
                        <Avatar
                          style={{
                            color: COMMONS.WHITE_COLOR,
                            backgroundColor: publicSettings?.PRIMARY_COLOR,
                          }}
                        >
                          {i + 1}
                        </Avatar>
                      }
                      title={
                        <p className="whitespace-pre-wrap font-bold">
                          {question.contents}
                        </p>
                      }
                      description={
                        <p>
                          {currentMember.campaignAnswers.find(
                            (a) =>
                              a?.campaignQuestionId ===
                              question?.campaignQuestionId
                          )?.contents || "ー"}
                        </p>
                      }
                    />
                  </List.Item>
                )}
              />
            </>
          ) : (
            ""
          )}
          <Divider />
          <motion.div
            className="flex justify-center"
            variants={COMMONS.ANIMATION_VARIANT_STAGGER_ITEM}
          >
            <TapAnimationComponent>
              <Button
                size="large"
                className="px-12"
                onClick={hideMemberDetailModal}
              >
                閉じる
              </Button>
            </TapAnimationComponent>
          </motion.div>
        </motion.div>
      </Modal>
    </>
  )
}

export default DetailModal
